import React, { useState, useEffect } from "react";
import { HiOutlineExternalLink } from "react-icons/hi";

const ProjectCard = ({ project }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const previewLength = 100;

  const toggleExpand = (e) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const description = project.description || "";
  const shouldTruncate = description.length > previewLength;
  const previewText = shouldTruncate
    ? `${description.slice(0, previewLength)}...`
    : description;

  return (
    <div className="project-card">
      <div className="thumbnail-container">
        <img
          src={project.thumbnail}
          alt={project.title}
          className="project-thumbnail"
        />
      </div>
      <div className="project-info">
        <h3 className="project-title">{project.title}</h3>
        <p className="project-category">{project.category}</p>
        <div className="project-description">
          <p>
            {isExpanded ? description : previewText}
            {shouldTruncate && (
              <div onClick={toggleExpand} className="see-more-link">
                {isExpanded ? " See less" : " See more"}
              </div>
            )}
          </p>
        </div>
        <a
          href={project.liveLink}
          target="_blank"
          rel="noopener noreferrer"
          className="visit-button"
        >
          <HiOutlineExternalLink size={16} />
          <span>Visit Site</span>
        </a>
      </div>
    </div>
  );
};

const Portfolio = ({ projects }) => {
  const [selectedCategories, setSelectedCategories] = useState(["all"]);
  const allCategories = [
    ...new Set(projects.map((project) => project.category)),
  ];
  const [filteredProjects, setFilteredProjects] = useState(projects);

  useEffect(() => {
    if (selectedCategories.includes("all")) {
      setFilteredProjects(projects);
    } else {
      setFilteredProjects(
        projects.filter((project) =>
          selectedCategories.includes(project.category)
        )
      );
    }
  }, [selectedCategories, projects]);

  const handleCategoryChange = (category) => {
    if (category === "all") {
      setSelectedCategories(["all"]);
    } else {
      const newCategories = selectedCategories.filter((c) => c !== "all");
      if (selectedCategories.includes(category)) {
        const updatedCategories = newCategories.filter((c) => c !== category);
        setSelectedCategories(
          updatedCategories.length === 0 ? ["all"] : updatedCategories
        );
      } else {
        setSelectedCategories([...newCategories, category]);
      }
    }
  };

  return (
    <div className="portfolio-section">
      <div className="container">
        <h2 className="section-title">My Portfolio</h2>

        <div className="portfolio-description">
          <p>
            Throughout my career, I've had the privilege of collaborating with
            these exceptional companies. Each project represents unique
            challenges and solutions where I've applied my expertise in web
            development, Salesforce implementations, and website design. Filter
            by category to explore specific types of projects and see how I've
            helped these organizations achieve their goals.
          </p>
        </div>

        <div className="filter-container">
          <div className="filter-group">
            <input
              type="checkbox"
              id="all"
              className="filter-checkbox"
              checked={selectedCategories.includes("all")}
              onChange={() => handleCategoryChange("all")}
            />
            <label htmlFor="all">All Projects</label>
          </div>

          {allCategories.map((category) => (
            <div key={category} className="filter-group">
              <input
                type="checkbox"
                id={category}
                className="filter-checkbox"
                checked={selectedCategories.includes(category)}
                onChange={() => handleCategoryChange(category)}
              />
              <label htmlFor={category}>{category}</label>
            </div>
          ))}
        </div>

        <div className="project-flex-container">
          {filteredProjects.map((project, index) => (
            <ProjectCard key={index} project={project} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./navigation/navbar";
import Footer from "./navigation/footer";
import PricingPage from "./components/portfolioPage";
import TeamPage from "./components/team";
import HomePage from "./components/homepage";

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/portfolio" element={<PricingPage />} />
            <Route path="/about" element={<TeamPage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

import React from "react";
import { FaSalesforce, FaCode, FaLaptopCode } from "react-icons/fa";
import ContactPage from "./contact";

const HomePage = () => {
  const handleSchedule = () => {
    window.open(
      "https://calendly.com/cooper-tintech/1-on-1-software-discovery-session-crafting-your-vision",
      "_blank"
    );
  };

  return (
    <div className="home-page">
      <section className="hero">
        <div className="hero-content">
          <h1>Transforming Ideas into Digital Reality</h1>
          <p>Hi, I'm Cooper - Your Full-Stack Software Engineer</p>
          <div className="hero-skills">
            <span>
              <FaCode /> Web Development
            </span>
            <span>
              <FaLaptopCode /> App Creation
            </span>
            <span>
              <FaSalesforce /> Salesforce Expert
            </span>
          </div>
          <button onClick={handleSchedule}>
            Let's Build Something Amazing
          </button>
        </div>
        <div className="hero-image">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/TingeyFam_BH-048.jpg?alt=media&token=55e44df0-fc15-4740-b89d-3bce4c87674a"
            alt="Cooper - Software Engineer"
          />
        </div>
      </section>

      <section className="cta">
        <h2>Ready to Elevate Your Business?</h2>
        <p>
          Let's discuss how we can leverage technology to drive your success.
        </p>
        <button onClick={handleSchedule}>
          Schedule Your Free Consultation
        </button>
      </section>

      <ContactPage id="contact" />
    </div>
  );
};

export default HomePage;

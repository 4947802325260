import React from "react";
import ContactPage from "./contact";
import Portfolio from "./portfolio";

const projects = [
  {
    title: "Ellucian - CampusLogic",
    category: "Web App",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/Ellucian.jpeg?alt=media&token=63f6b4a1-bf44-4909-b812-f4905e4f578b",
    liveLink: "https://www.ellucian.com/",
  },
  {
    title: "LA Chamber of Commerce",
    category: "Salesforce",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/lachamber.png?alt=media&token=32fdbc45-03ee-433c-919e-6c986b76aab5",
    liveLink: "https://lachamber.com/",
  },
  {
    title: "Church of Jesus Christ of Latter-day Saints",
    category: "Web App",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/church.jpeg?alt=media&token=da2d0e94-0261-4c5d-907b-f9b95d04b15a",
    liveLink: "https://www.churchofjesuschrist.org/?lang=eng",
  },
  {
    title: "Porter Mountain Advisors",
    category: "Website",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/pma.jpg?alt=media&token=5f1b3845-2299-4cfb-9745-cc572c86dbb7",
    liveLink: "https://www.portermtnadvisors.com/",
  },
  {
    title: "LowPriceTravels",
    category: "Web App",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/lowpricetravels.png?alt=media&token=a7545d29-4e7f-4c27-ae42-b86b8658abbb",
    liveLink: "https://lowpricetravels.com",
  },
  {
    title: "Intellipay",
    category: "Web App",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/intellipay.png?alt=media&token=6ded4b86-c2ea-4aea-bebe-3429e941d2bf",
    liveLink: "https://intellipay.com/",
  },
  {
    title: "Wyssling Consulting",
    category: "Web App",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/wyssling.png?alt=media&token=0dedcdf5-3da8-42a1-b5fb-72eb92d6804b",
    liveLink: "https://www.wysslingconsulting.com/",
  },
  {
    title: "EPYPE",
    category: "Web App",
    description: "",
    thumbnail:
      "https://firebasestorage.googleapis.com/v0/b/tintech-solutions.appspot.com/o/epype.jpeg?alt=media&token=0d3b78ec-6ef9-4bfc-a40c-ce26d8cb1685",
    liveLink: "https://www.epype.io/",
  },
];

const PricingPage = () => {
  return (
    <div className="pricing-page">
      <Portfolio projects={projects} />
      <ContactPage id="contact" />
    </div>
  );
};

export default PricingPage;
